import SpecialDatePicker from "./SpecialDatePicker";
import {useEffect, useState} from "react";
import SpecialTextInput from "./SpecialTextInput";

export default function EditableSpecialSchedule({index, schedule, onChange, deleteLine, config, isDateOk}) {

    let [currentSchedule, setCurrentSchedule] = useState(schedule)
    useEffect(() => setCurrentSchedule(schedule), [schedule])

    return (
        <>
            <td>
                <SpecialTextInput text={currentSchedule["cause"]}
                                  onChange={(field, value) => onChange(index, field, value)}
                                  field={"cause"} options={config["causes"]}/>
            </td>
            <td>
                <SpecialTextInput text={currentSchedule["type"]}
                                  field={"type"} options={config["types"]}
                                  onChange={(field, value) => onChange(index, field, value)}/>
            </td>
            <td>
                <SpecialDatePicker field={"dateDebut"} date={currentSchedule.dateDebut}
                                   onChange={(field, value) => onChange(index, field, value)}/>
            </td>
            <td>
                <SpecialDatePicker field={"dateFin"} date={currentSchedule.dateFin}
                                   onChange={(field, value) => onChange(index, field, value)}/>
            </td>
        </>)
}