import {useCallback, useEffect, useState} from "react";
import TimePicker from "./TimePicker";
import {useTimeToNumber} from "../services/utils";


export default function EditableSchedule({schedule, day, onChange, errors}) {
    const timeToNumber = useTimeToNumber()
    let [currentSchedule, setCurrentSchedule] = useState(schedule)


    let manageSlot = useCallback((slot, nextSlot, force) => {

        if ((force || timeToNumber(currentSchedule[`${nextSlot}${day}`]) > 0) && timeToNumber(currentSchedule[`${slot}${day}`]) > timeToNumber(currentSchedule[`${nextSlot}${day}`])) {
            setCurrentSchedule(prevState => {
                prevState[`${slot}${day}`] = currentSchedule[`${nextSlot}${day}`]
                return {...prevState}
            })
        }
    }, [currentSchedule, day, timeToNumber])

    useEffect(() => {
            if (currentSchedule) {
                manageSlot("heureDebutPeriode1", "heureFinPeriode1", true)
                manageSlot("heureFinPeriode1", "heureDebutPeriode2", false)
                manageSlot("heureDebutPeriode2", "heureFinPeriode2", false)
            }
        }
        ,
        [currentSchedule, manageSlot]
    )

    useEffect(() => setCurrentSchedule(schedule), [schedule])

    useEffect(() => {
    }, [errors])


    return (
        <tr key={day}>
            <td className="px-6 py-4 whitespace-nowra text-right">
                <div className="flex text-right">
                    <div className="ml-4">
                        <span>{day}</span>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="ml-4">
                        {currentSchedule ?
                            <TimePicker
                                isError={errors && !!errors[`heureDebutPeriode1${day}`]}
                                readOnly={currentSchedule[`estFerme${day}`]}
                                field={`heureDebutPeriode1${day}`}
                                time={currentSchedule[`heureDebutPeriode1${day}`]}
                                onChange={onChange}
                                max={currentSchedule[`heureFinPeriode1${day}`]}/> :
                            <div className={"animate-pulse rounded bg-gray-500 w-12 md:w-24 h-7 mx-2 m-1"}/>}
                    </div>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="ml-4">
                        {currentSchedule ?
                            <TimePicker readOnly={currentSchedule[`estFerme${day}`]} field={`heureFinPeriode1${day}`}
                                        time={currentSchedule[`heureFinPeriode1${day}`]}
                                        onChange={onChange}
                                        min={currentSchedule[`heureDebutPeriode1${day}`]}
                                        max={currentSchedule[`heureDebutPeriode2${day}`]}/> :
                            <div className={"animate-pulse rounded bg-gray-500 w-12 md:w-24 h-7 mx-2 m-1"}/>}

                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="ml-4">
                        {currentSchedule ?
                            <TimePicker readOnly={currentSchedule[`estFerme${day}`]} field={`heureDebutPeriode2${day}`}
                                        time={currentSchedule[`heureDebutPeriode2${day}`]} onChange={onChange}
                                        min={currentSchedule[`heureFinPeriode1${day}`]}
                                        max={currentSchedule[`heureFinPeriode2${day}`]}/> :
                            <div className={"animate-pulse rounded bg-gray-500 w-12 md:w-24 h-7 mx-2 m-1"}/>}

                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="ml-4">
                        {currentSchedule ?
                            <TimePicker readOnly={currentSchedule[`estFerme${day}`]} field={`heureFinPeriode2${day}`}
                                        time={currentSchedule[`heureFinPeriode2${day}`]}
                                        onChange={onChange}
                                        min={currentSchedule[`heureDebutPeriode2${day}`]}/> :
                            <div className={"animate-pulse rounded bg-gray-500 w-12 md:w-24 h-7 mx-2 m-1"}/>}

                    </div>
                </div>
            </td>
            <td className="px-6 py-4 white space-nowrap font-medium">
                <div className="flex items-center">
                    {currentSchedule ?
                        <input
                            className="form-tick h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
                            type="checkbox"
                            checked={currentSchedule[`estFerme${day}`]}
                            onChange={e => {
                                setCurrentSchedule(prevState => {
                                    prevState[`estFerme${day}`] = e.target.checked
                                    return ({...prevState})
                                })
                                onChange(`estFerme${day}`, e.target.checked)
                            }}
                        /> : <div className={"animate-pulse rounded bg-gray-500 w-7 h-7 mx-2 m-1"}/>}
                </div>

            </td>
        </tr>
    )
}