import {useEffect, useState} from "react";

export default function TimePicker({field, time, onChange, readOnly, className, isError, min, max}) {
    const [currentTime, setCurrentTime] = useState(time)

    useEffect(() => setCurrentTime(time), [time])

    const inputClassName = `appearance-none rounded-sm w-full py-1 px-3 focus:outline-none focus:border-gray-400 focus:shadow-outline ${readOnly ? "text-gray-500 border border-gray-200 bg-gray-200" : isError ? "bg-red-500" : "bg-white border-gray-100 border text-gray-700"} ${className}`

    return (<div>
        <input step={60 * 15} className={inputClassName} value={currentTime}
               onChange={(e) => {
                   setCurrentTime(e.target.value)
                   onChange(field, e.target.value)
               }} type="time" max={max} min={min}
               readOnly={readOnly}/>

    </div>)
}