import axios from "axios"
import {useEffect, useRef} from "react";
import {useKeycloak} from "@react-keycloak/web";

export const useAxios = () => {
    const axiosInstance = useRef(axios.create());
    const {keycloak, initialized} = useKeycloak();
    const kcToken = keycloak?.token ?? '';
    useEffect(() => {
        axiosInstance.current = axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
            timeout: 10_000,
            timeoutErrorMessage: "Le serveur n'a pas renvoyé de réponse à temps, veuillez contacter un administrateur",
            headers: {
                Authorization: initialized ? `Bearer ${kcToken}` : undefined,
            },
        });

        axiosInstance.current.interceptors.response.use(r => r, e => {
            if (e.response.status === 401) {
                axiosInstance.current.defaults.headers["Authorization"] = `Bearer ${keycloak.updateToken(120).then(t => {
                    console.log(t);
                    return t
                })}`
            } else {
                return e
            }
        })

        return () => {
            axiosInstance.current = undefined;
        };
    }, [initialized, kcToken, keycloak]);

    return axiosInstance;
};