import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useKeycloak} from "@react-keycloak/web";

export function hasRole(roles, keycloak) {

    for (const role of roles) {
        if (keycloak?.hasResourceRole(role, 'selfcare-front') || keycloak?.hasRealmRole(role)) return true
    }
    return false
}


const ProtectedRoute = ({component: Component, ...rest}) => {
    const {keycloak} = useKeycloak()


    return <Route
        {...rest}
        render={(props) => {
            return keycloak?.authenticated && hasRole(rest.roles, keycloak) ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: props.location},
                    }}
                />
            )
        }
        }
    />
}

export default ProtectedRoute;