import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useAxios} from "../services/httpclient";
import {toast} from "react-toastify";


export default function Stores() {

    const [stores, setStores] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const [hasCodeSap, setHasCodeSap] = useState()
    const {keycloak} = useKeycloak()
    const axios = useAxios()
    let history = useHistory()



    useEffect(() => {
        setLoaded(false)
        setStores([])
        let codeSapAttribute = "list_code_sap"
        if (keycloak) {
            keycloak.loadUserInfo().then(res => {
                debugger
                setHasCodeSap(res[codeSapAttribute])
                if (res[codeSapAttribute]) {
                    Promise.all(res[codeSapAttribute]
                        .split(";")
                        .map(s => !!axios.current && axios.current.get(`/pointsofsale/${s}/info`))
                    ).then(res => {
                            setStores(res.map(r => r.data))
                            setLoaded(true)
                        }
                    ).catch(e => {
                            toast.error(e.message)
                        }
                    )
                }
            })
        }
    }, [keycloak, axios])


    function onRowClick({codeSAP}) {
        history.push("/stores/" + codeSAP + "/schedule")
    }

    let skeleton = "animate-pulse rounded bg-gray-500 w-36 md:w-72 h-4";
    return (
        <>  {hasCodeSap ? <div className="space-y-6 pt-8">
            <span className="uppercase text-2xl">Restaurants disponibles</span>
            <div className="md:flex md:flex-1 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Restaurant
                        </th>
                        <th
                            scope="col"
                            className="hidden md:flex px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Ouverture
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Code SAP
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {isLoaded ? stores.map((store) => (
                        <tr key={`${store.idRestaurant}${store.codeSAP}`}
                            className={"hover:bg-gray-100 cursor-pointer"}
                            onClick={() => onRowClick(store)}>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                    <div className="text-sm font-medium text-gray-900">{store.nomWeb}</div>
                                </div>
                            </td>
                            <td className="hidden md:flex px-6 py-4 whitespace-nowrap">
                <span
                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {store.statut}
                </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{store.codeSAP}</td>
                        </tr>
                    )) : <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                            <div className={skeleton}/>
                        </td>
                        <td className="hidden md:flex px-6 py-4 whitespace-nowrap">
                            <div className={skeleton}/>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                            <div className={skeleton}/>
                        </td>
                    </tr>}
                    </tbody>
                </table>
            </div>
        </div> : <div>Vous n'avez pas de restaurant à votre charge. Veuillez contacter un administrateur</div>
        }</>
    )
}